<template>
  <div :key="settingsComponent">
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="10" class="d-flex align-items-center">
        <h3>
          {{ $t('settings.project.projectType.name') }}
        </h3>
        <b-dropdown
            class="context-menu"
            toggle-class="dot-button"
            no-caret
            no-flip
            right
        >
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true" scale="1"></b-icon>
          </template>
          <b-dropdown-item @click="copySettings">
            {{ $t('dropdowns.copySettings') }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="settingsCopied"
            @click="pasteSettings"
          >
            {{ $t('dropdowns.pasteSettings') }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col cols="12" offset-sm="1" sm="10">
        <hr>
      </b-col>
      <b-col cols="12" offset-sm="1" sm="10">
        <p>
          {{ $t('settings.project.projectType.description') }}
        </p>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label class="mb-0 mt-2">
          {{ $t('settings.project.projectType.projectTypeLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-select
          :value="theme.themeId"
          :options="themeList"
          @change="changeThemeId"
        ></b-form-select>
      </b-col>
    </b-row>
    <theme-settings-wrapper
      class="mb-4 pb-4"
      v-if="theme.themeId"
      :theme="theme"
      @update-theme-settings="updateThemeSettings"
    />
  </div>
</template>

<script>
import GetAllThemes from '@/graphQlQueries/queries/getAllThemes';

export default {
  name: 'ProjectSettingsProjectType',
  components: {
    ThemeSettingsWrapper: () => import('@/components/settings/projects/themes/ThemeSettingsWrapper.vue'),
  },
  props: {
    themeId: {
      type: Number,
      default: null,
    },
    groupId: {
      type: Number,
      default: null,
    },
    themeSettings: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    theme: {
      themeId: null,
      settings: null,
    },
    settingsCopied: false,
    settingsComponent: 0,
  }),
  computed: {},
  apollo: {
    themeList: {
      query: GetAllThemes,
      variables() {
        return {
          groupId: this.groupId,
        };
      },
      update(data) {
        return data.themes.map((theme) => ({
          value: theme.theme_id,
          text: this.$t(`settings.project.projectType.themeSetting.${theme.name}.name`),
        }));
      },
      skip() {
        return !this.groupId;
      },
    },
  },
  created() {
    this.theme.themeId = this.themeId;
    this.theme.settings = this.themeSettings;
    if (localStorage.getItem('navigation_settings')) {
      this.settingsCopied = true;
    }
  },
  methods: {
    changeThemeId(newThemeId) {
      this.theme.themeId = newThemeId;
      this.updateTheme();
    },
    updateThemeSettings(themeSettingValues) {
      this.theme.settings = themeSettingValues;
      this.updateTheme();
    },
    updateTheme() {
      this.$emit('update-theme-settings', this.theme);
    },
    copySettings() {
      localStorage.setItem('navigation_settings', JSON.stringify(this.theme));
      this.settingsCopied = true;
    },
    pasteSettings() {
      const newSettings = localStorage.getItem('navigation_settings');
      this.theme.themeId = JSON.parse(newSettings).themeId;
      this.theme.settings = JSON.parse(newSettings).settings;

      this.settingsComponent += 1;
      this.updateTheme();
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .btn.dot-button {
  color: #b9b9b9;
  background: transparent;
  border: none;
  margin-bottom: 0.5rem;

  &:hover {
    color: #fff;
  }
}
</style>
